import React, { useEffect, useState } from "react";

import config from "../../../../../config";
import { useApi } from "../../../../../api";
import Invoice from "./Invoice";
import Dialog from "./Dialog";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import LoadingOverlay from "../../../../../elements/LoadingOverlay";

const InvoiceContainer = ({ invoiceId, onClose, onUpdate }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const [invoice, setInvoice] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showDialogLoader, setDialogLoading] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(null);

  useEffect(() => {
    setLoading(true);

    api.get(`/v2/invoices/${invoiceId}`).then(response => {
      console.log(response.data);
      setInvoice(response.data);
      setLoading(false);
    });
  }, [api, invoiceId]);

  useEffect(() => {
    setInvoiceStatus(invoice.status);
  }, [invoice]);

  const handleExport = () => {
    const location = `${config.api.baseUrl}/v2/invoices/${invoice.id}/create-invoice`;
    window.location.href = location;
  };

  const handleUpdateStatus = status => {
    setInvoiceStatus(status);
    api.put(`/v2/invoices/${invoiceId}/status`, { status }).then(() => {
      onUpdate();

      enqueueSnackbar("system.billing.responses.statusUpdated", {
        variant: "success"
      });
    });
  };

  const handleCreateInvoice = invoice => {
    setDialogLoading(true);
    api
      .post(`/v2/invoices/${invoiceId}/create-invoice`, { invoice })
      .then(() => {
        handleUpdateStatus("handled");
        setDialogLoading(false);
        enqueueSnackbar("system.billing.responses.invoiceCreated", {
          variant: "success"
        });
      })
      .catch(err => {
        setDialogLoading(false);
        if (err.response && err.response.data) {
          const code = err.response.data.code;
          let message = `system.billing.responses.${err.response.data.exception}`;
          if (code >= 400 && code < 500) {
            message = err.response.data.message;
          }
          enqueueSnackbar(message, {
            variant: "error"
          });
        } else {
          enqueueSnackbar(`system.billing.responses.genericError`, {
            variant: "error"
          });
        }
      });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog onClose={onClose} invoiceId={invoiceId}>
      <LoadingOverlay isLoading={showDialogLoader} progressType="circular">
        <Invoice
          invoice={invoice}
          invoiceStatus={invoiceStatus}
          onUpdateStatus={handleUpdateStatus}
          onExport={handleExport}
          onClose={onClose}
          onCreateInvoice={handleCreateInvoice}
        />
      </LoadingOverlay>
    </Dialog>
  );
};

export default InvoiceContainer;
